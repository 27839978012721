<template>
  <a-layout>
    <a-layout-content :style="{ padding: '0', margin: '0', backgroundColor:'#3A393C' }">
      <div class="loading" v-if="this.loading">
        <a-spin size="large" />
      </div>
      <div class="container" v-if="!this.loading">
        <div class="title">{{chapter.title}}</div>
        <div :style="{ width:'100%', padding:'30px 10px 0px 0px' }">
          <a-row type="flex" justify="space-between" align="middle">
            <a-col :span="4"></a-col>
            <a-col :span="4" style="text-align:left">
              <a-button type="link" ghost @click="handlePrevClick()">
                <a-icon type="arrow-left" class="iconFont" />
              </a-button>
            </a-col>
            <a-col :span="4" style="text-align:right">
              <a-button type="link" ghost @click="handleNextClick()">
                <a-icon type="arrow-right" class="iconFont" />
              </a-button>
            </a-col>
            <a-col :span="4"></a-col>
          </a-row>
        </div>

        <div class="content" v-html="chapter.content" />
        
        <div :style="{ width:'100%', padding:'0px 10px 30px 0px' }">
          <a-row type="flex" justify="space-between" align="middle">
            <a-col :span="4"></a-col>
            <a-col :span="4" style="text-align:left">
              <a-button type="link" ghost @click="handlePrevClick()">
                <a-icon type="arrow-left" class="iconFont" />
              </a-button>
            </a-col>
            <a-col :span="4" style="text-align:right">
              <a-button type="link" ghost @click="handleNextClick()">
                <a-icon type="arrow-right" class="iconFont" />
              </a-button>
            </a-col>
            <a-col :span="4"></a-col>
          </a-row>
        </div>
      </div>
      <a-back-top />
      <CustomSetting
        :visible="this.customSettingVisible"
        :fontSize="this.$store.state.customSettings.fontSize"
        :immersive="this.$store.state.customSettings.immersive"
        @callbackData="callbackData"
      />
      <div class="scroll"></div>
    </a-layout-content>
    <a-layout-footer
      v-if="!this.loading"
      :style="{ position: 'fixed', zIndex: 1, bottom:0, width: '100%', padding:'0px 0px 0px 0px'}"
    >
      <div>
        <a-row type="flex" justify="space-between" align="middle">
          <a-col :span="4" style="text-align:left;padding-left:15px">
            <a-button type="link" size="large" shape="circle" @click="handleOnToList">
              <a-icon type="rollback" class="iconFont" />
            </a-button>
          </a-col>
          <a-col :span="4" style="text-align:center">
            <router-link v-bind:to="'/home'">
              <a-icon type="home" class="iconFont" />
            </router-link>
          </a-col>
          <a-col :span="4" style="text-align:right;padding-right:15px">
            <a-button type="link" size="large" shape="circle" @click="handleOnCustomSettingOpen">
              <a-icon type="setting" class="iconFont" />
            </a-button>
          </a-col>
        </a-row>
      </div>
    </a-layout-footer>
  </a-layout>
</template>

<script>
import Vue from "vue";
import CustomSetting from "../components/CustomSetting";
import ContentCommon from "./ContentCommon";

Vue.prototype.common = ContentCommon;

const base_API_URL = "api/novel/getChapter/";

export default {
  data() {
    return {
      ContentCommon,
      top: 0,
      path: "immersive",
      novel: {
        id: this.$route.query.id,
        name: this.$route.query.name
      },
      chapter: {
        id: this.$route.query.id,
        title: "Loading...",
        content: "",
        url: "",
        previousUrl: "",
        nextUrl: ""
      },
      url: this.$route.query.url,
      loading: true,
      busy: false,
      customSettingVisible: false,
      originalClientHeight: document.documentElement.clientHeight
    };
  },
  components: {
    CustomSetting
  },
  computed: {
    storeNovel() {
      //获取store中的数据，需要将代码放置在computed中方可。
      return this.$store.state.novel;
    },
    storeCustomSettings() {
      var setting = this.$store.state.customSettings;
      //console.log(setting)
      return setting;
    }
  },
  watch: {
    "storeCustomSettings.fontSize": {
      handler(val) {
        this.common.setFontSize(val);
      }
    },
    "storeCustomSettings.theme": {
      handler(val) {
        this.common.setTheme(val);
      },
      deep: true
    }
  },
  created() {
    var _this = this;
    //console.log("created")
    //从localStorage中加载设置
    var data = JSON.parse(localStorage.getItem("CustomSettings"));
    if (data != null) {
      this.$store.commit("setCustomSettings", data);
    }

    document.onkeydown = function(e) {
      let keyCode = window.event.keyCode;

      if (keyCode == 37) {
        //方向左
        _this.handlePrevClick();
      }
      if (keyCode == 39) {
        //方向右
        _this.handleNextClick();
      }
    };

    //触底事件
    //window.addEventListener("scroll", this.handleScroll);
  },
  
  beforeMount() {
    //console.log("beforeMount")
    this.common.setFontSize();
    this.fetchData(this.url);
  },

  mounted() {
    //console.log("mounted")
  },

  beforeUpdate() {
    //console.log("beforeUpdate")
    this.url = this.$route.query.url;
    this.common.setHistory(this.url, this.novel);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollEvent);
  },

  methods: {
    callbackData(val) {
      this.customSettingVisible = val;
    },
    routeRedirect() {
      setTimeout(
        () =>
          this.$router.push(
            {
              path: "chapters",
              query: { id: this.novel.id, name: this.novel.name }
            },
            onComplete => {},
            onAbort => {}
          ),
        1000
      );
    },
    fetchData(url) {
      this.loading = true;
      this.chapter.title = "Loading...";
      this.chapter.content = "";

      this.$axios
        .get(base_API_URL, { params: { id:this.novel.id,url: url } })
        .then(res => {
          if (res.data.code != 0) {
            this.loading = true;
            this.$message.error(res.data.message);

            this.routeRedirect();
            return;
          }

          this.chapter = res.data.data;
          this.loading = false;
          document.title = this.chapter.title;
        })
        .catch(error => {
          console.warn(error);
          this.loading = true;
          this.$message.error("发生错误！即将返回列表页！");
          setTimeout(
            () =>
              this.$router.push(
                {
                  path: "chapters",
                  query: { id: this.novel.id, name: this.novel.name }
                },
                onComplete => {},
                onAbort => {}
              ),
            1000
          );
        });
    },
    handleOnToList() {
      this.$router.push(
        {
          name: "chapters",
          query: {
            id: this.novel.id,
            name: this.novel.name,
            url: this.chapter.previousUrl
          }
        },
        onComplete => {},
        onAbort => {}
      );
    },
    handleOnCustomSettingOpen() {
      this.customSettingVisible = true;
    },
    handleScroll() {
      this.top = document.documentElement.clientHeight - 90;
      //触底事件，自动跳转至下一页
      if (
        document.documentElement.scrollTop +
          document.documentElement.clientHeight >=
        document.body.scrollHeight
      ) {
        //this.handleNextClick();
      }
    },
    handlePrevClick() {
      if (
        this.chapter == null ||
        this.chapter.previousUrl == "" ||
        this.chapter.previousUrl == "NOTHING"
      ) {
        this.$message.warning("没有上一页了！");
        return;
      }
      this.fetchData(this.chapter.previousUrl);
      this.$router.push(
        {
          path: this.path,
          query: {
            id: this.novel.id,
            name: this.novel.name,
            url: this.chapter.previousUrl
          }
        },
        onComplete => {},
        onAbort => {}
      );
    },
    handleNextClick() {
      if (
        this.chapter == null ||
        this.chapter.nextUrl == "" ||
        this.chapter.nextUrl == "NOTHING"
      ) {
        this.$message.warning("没有下一页了！");
        return;
      }
      this.fetchData(this.chapter.nextUrl);
      this.$router.push(
        {
          path: this.path,
          query: {
            id: this.novel.id,
            name: this.novel.name,
            url: this.chapter.nextUrl
          }
        },
        onComplete => {},
        onAbort => {}
      );
    }
  }
};
</script>
<style scoped>
.container {
  min-height: 700px;
  padding: 20px 15px 20px 15px;
  margin: 0;
  word-wrap: break-word;
  /*
  color: rgb(206,206,207); 
  background-color: rgb(58, 57, 60);
  */
  color: var(--font-color);
  background-color: var(--background-color);
}
.content-container {
  padding: 0px;
  margin: 0px;
}
div {
  /*background-color: rgb(58, 57, 60);*/ /* #3A393C */
  background-color: var(--background-color);
}
body {
  /*background-color: rgb(58, 57, 60);*/
  background-color: var(--background-color);
  --font-size: 20px;
  --title-size: 24px;
}
.title {
  font-weight: bold;
  font-size: var(--title-size);
}
.iconFont {
  font-size: 28px;
  color: var(--font-color);
}
.content {
  margin: 30px 0px 30px 0px;
  text-indent:2em; 
  font-size: var(--font-size);
  /*
  line-height:40px;
  font-family: KaiTi,"Microsoft YaHei",微软雅黑,"MicrosoftJhengHei",华文细黑,STHeiti,MingLiu;
  Mac
  ----
  苹方  PingFang SC
  宋体  STSong
  细黑  STXihei
  华文仿宋 STFangsong
  楷体  STKaiti
  圆体  Yuanti SC
  娃娃体  Wawati SC

  Windows
  -----
  宋体  SimSun
  微软雅黑  Microsoft Yahei
  楷体  KaiTi
  仿宋  FangSong
  */
  /*font-family: "STSong","Microsoft YaHei","黑体","宋体",sans-serif;*/
}
.row {
  line-height: 40px;
  height: 40px;
}
.footer {
  text-align: center;
  padding-bottom: 35px;
}
.loading {
  min-height: 700px;
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}
</style>